#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: auto;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}
.home__deteils {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}


.app > * {
  margin-bottom: 0; /* Remove bottom margin */
}


.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  // background-color: red;
  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 5rem 1rem 0rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    position: absolute;
    height: auto;
    top: 4rem;
    z-index: 1;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1200px) {
    }
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
    @media screen and (max-width: 1200px) {
    margin-top: 2rem;
    z-index: 0;
    }
  }

  .desktop-badge{
    @media screen and (max-width: 1200px) {
      display: none;
      }
  }
  .mobile-badge{
    display: none;
    @media screen and (max-width: 1200px) {
      margin-left: 0.5rem;
      padding: 0.5rem 1rem;
      display: flex;
      }
  .badge-text{
    text-align: right;
    text-transform: uppercase;
  }

  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    margin-top: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }


  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    object-fit: contain;
    z-index: 1;
  @media screen and (max-width: 1200px) {
  }
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 90%;
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 1200px) {
   margin-top: 4rem;
    position: static;
    top: 8rem;
    height: auto;

  }
}

.download_cv{
  margin-bottom: 50%;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.home_bottom_bar{
  z-index: 1;
  padding: 0.5rem;
  position: absolute;
  bottom: 4rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0.5rem;
    margin: 0;
    position: absolute;
    bottom: 0;
  }
}

.bottom_cv{
  width: 100%;
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
  .bottom_cv-button{
    margin-bottom: 0.5rem;
  }
}

.get_quotation{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: gainsboro;
  padding: 0.5rem;
  border: 1px grey solid;
  border-radius: 10px;

  .get_quotation-input{
    width: 100%;
  }  

  .get_quotation-button{
    width: 100%;
    margin-top: 0.5rem;
  }  
}